import { Controller } from "stimulus"

// Attributes
const tokenThemeAttr = "data-gtm-theme-token-param"
const partnerIdAttr = "data-gtm-partner-id-param"
const memberIdAttr = "data-gtm-member-id-param"
const tradKeyAttr = "data-gtm-tkey"
const nameAttr = "data-gtm-name-param"
const idAttr = "data-gtm-id-param"
const activeItemAttr = "data-active-item-param"
const catalogAttr = "data-gtm-catalog" //  GRID_CATALOG_FAMILY || GRID_CATALOG_PRO
const productGranitAttr = "data-gtm-product-granit-param"
const productIsAccAttr = "data-gtm-is-accessory-param"
const newsTypeAttr = "data-gtm-news-type-param"
const destinationAttr = "data-gtm-destination"
const btnSignificationAttr = "data-gtm-btn-action"
const isProductAllGranitsModalAttr = "data-fromproductgranits"
const fromComparatorAttr = "data-fromcomparator"
const adminObjectAttr = "data-gtm-admin-object"
//
const catalogTypeAttr = "data-gtm-catalog-type"
// NEWS params
const newsIdAttr = "data-gtm-news-id-param"
const newsTitleAttr = "data-gtm-news-title-param"
const newsTextAttr = "data-gtm-news-text-param"
const newsTagsAttr = "data-gtm-news-tags-param"
const newsLinkAttr = "data-gtm-news-link-param"
const newsImglinkAttr = "data-gtm-news-imglink-param"
const newsVideotypeAttr = "data-gtm-news-videotype-param"
const newsVideoidAttr = "data-gtm-news-videoid-param"

export default class GtmController extends Controller {
  static targets = [
    "body",
    // Login
    "usernameInput",
    "loginSubmitBtnText",
    "loginErrorMsg",
    "forgetPasswordPage",
    // Catalog
    "catalogBoxesBlock",
    "catalogBox",
    // Monument Preview
    // Pages
    "loginPage",
    "homePage",
    "dashboardPage",
    "granitsPage",
    "productPreviewGranit",
    "newsPage",
    "newsArticlePage",
    // News
    "newsArticle",
    "newsTag",
    // Modals
    "modalComparator",
    "modalMnt", // monument preview
    "modalAcc", // accessory preview
    "modalMonumentGranits", // monument - tous les granits
    "modalAccessoryGranits", // accessory - tous les granits
    // Autre
    "subnavbar"
  ]

  connect() {
    // Global
    this.gtmSended = false

    // Init dataLayer
    window.dataLayer = window.dataLayer || []
    // END Init dataLayer

    // Login page
    this.clickedBtn = false
    if (this.hasLoginErrorMsgTarget) {
      this.loginFailed()
    } else if (this.hasLoginPageTarget) {
      this.displayLoginPage()
    }

    if (this.hasForgetPasswordPageTarget) {
      this.displayForgetPasswordPage()
    }
    // End Login page

    // Pages
    if (this.hasHomePageTarget) {
      this.displayHomePage()
    } else if (this.hasDashboardPageTarget) {
      this.displayHomePage(true)
    }

    if (this.hasCatalogBoxesBlockTarget) {
      // CATALOG_FAMILY || CATALOG_PRO
      this.displayCatalogPage(this.catalogBoxesBlockTarget.getAttribute(catalogAttr))
    }

    // NEWS list
    if (this.hasNewsPageTarget) {
      this.displayNewsPage()
    } else if (this.hasNewsArticlePageTarget) {
      this.selectArticle()
      this.displaySingleNews()
    }
    // End Pages
  }

  // ######################## catalog type (catalog famille, pro, ..) ########################
  static staticCatalogType(customisedCatalog = null) {
    const bodyTarget = this.bodyTarget || document.querySelector("[data-target='gtm.body']")
    const catalog = customisedCatalog !== null ? customisedCatalog : bodyTarget.getAttribute(catalogTypeAttr)
    let from
    switch (catalog) {
      case "family":
        from = "catalogue"
        break
      case "pro":
        from = "catalogue prix"
        break
      case "granite":
        from = "catalogue granits"
        break
      default:
        from = "catalogue"
        break
    }
    return from
  }

  catalogType(type) {
    return GtmController.staticCatalogType(type)
  }

  // ######################## Login Page ########################
  displayLoginPage() {
    this.clearObjectExceptAgrs()
    window.dataLayer.push({ // eslint-disable-line no-undef
      event: "page.display",
      page: {
        template: "login",
        subtemplate: this.bodyTarget.getAttribute(tokenThemeAttr) || ""
      },
      partner: null,
      member: { logged: false }
    })
  }

  logged(event) {
    if (this.clickedBtn) {
      this.clickedBtn = false
      return
    }
    event.preventDefault()
    this.clearObjectExceptAgrs()
    window.dataLayer.push({ // eslint-disable-line no-undef
      partner: null,
      member: { logged: false },
      event: "cta.click",
      login: { id: this.usernameInputTarget.value },
      cta: {
        name: this.loginSubmitBtnTextTarget.getAttribute(tradKeyAttr),
        category: "accueil",
        subcategory: "",
        type: "cta",
        from: "login"
      }
    })
    this.clickedBtn = true
    event.currentTarget.click()
  }

  loginFailed() {
    window.dataLayer.push({ // eslint-disable-line no-undef
      event: "error.display",
      page: {
        template: "login",
        subtemplate: this.bodyTarget.getAttribute(tokenThemeAttr) || ""
      },
      partner: null,
      member: { logged: false },
      error: {
        name: "login incorrect",
        category: "login",
        type: "fonctionnel",
        message: this.loginErrorMsgTarget.getAttribute(tradKeyAttr)
      }
    })
  }

  displayForgetPasswordPage() {
    window.dataLayer.push({ // eslint-disable-line no-undef
      event: "page.display",
      page: {
        template: "login",
        subtemplate: "changement mot de passe"
      },
      partner: null,
      member: { logged: false }
    })
  }

  // ######################## Home Page ########################
  displayHomePage(old = false) {
    this.clearObjectExceptAgrs()
    window.dataLayer.push({ // eslint-disable-line no-undef
      event: "page.display",
      page: {
        template: old ? "accueil old" : "accueil",
        subtemplate: ""
      },
      partner: { id: this.bodyTarget.getAttribute(partnerIdAttr) || "" },
      member: {
        logged: true,
        id: this.bodyTarget.getAttribute(memberIdAttr) || ""
      }
    })
  }

  // _____________ HEADER _____________

  navBarLinks(event) {
    event.preventDefault()
    const linkedElement = event.currentTarget // Tag A
    const key = linkedElement.getAttribute(tradKeyAttr)
    // attributes category & subcategory
    const gtmObj = linkedElement.getAttribute(adminObjectAttr)
    const gtmObjJson = this.isJSON(gtmObj) ? JSON.parse(gtmObj) : {}
    const targetAttribute = linkedElement.getAttribute("target")
    //
    const ctaObj = {
      ...gtmObjJson,
      name: key,
      type: "lien",
      from: "topnavbar"
    }
    //  Send event
    this.clearObjectExceptAgrs()
    window.dataLayer.push({
      event: "cta.click",
      partner: { id: this.bodyTarget.getAttribute(partnerIdAttr) || "" },
      member: {
        logged: true,
        id: this.bodyTarget.getAttribute(memberIdAttr) || ""
      },
      cta: ctaObj
    })

    if (targetAttribute && targetAttribute === "_blank") {
      window.open(linkedElement.href, "_blank")
    } else {
      // Open link in the same tab
      window.location.href = linkedElement.href
    }
  }

  navSubmenuBtn(event) {
    const linkedElement = event.currentTarget // Tag A
    const name = linkedElement.getAttribute(nameAttr)
    const key = linkedElement.getAttribute(tradKeyAttr)
    //
    let category, subcategory
    switch (name) {
      case "page_onboarding":
        category = ""
        subcategory = ""
        break
      case "page_contact":
        category = "contact"
        subcategory = "liste contacts"
        break
      case "page_faq":
        category = "faq"
        subcategory = ""
        break
      case "page_mysettings":
        category = "gestion compte"
        subcategory = "profil"
        break
      case "topbaritem_disconnect":
        category = "login"
        subcategory = ""
        break
    }
    //  Send event
    this.clearObjectExceptAgrs()
    window.dataLayer.push({
      event: "cta.click",
      partner: { id: this.bodyTarget.getAttribute(partnerIdAttr) || "" },
      member: {
        logged: true,
        id: this.bodyTarget.getAttribute(memberIdAttr) || ""
      },
      cta: {
        name: key,
        category: category,
        subcategory: subcategory,
        type: "menu",
        from: "topnavbar"
      }
    })
  }

  // _____________ END HEADER _____________

  chooseCollection(event) {
    const currentElement = event.currentTarget
    const key = currentElement.getAttribute(tradKeyAttr)
    const name = currentElement.getAttribute(nameAttr)
    const isImg = !!name

    this.clearObjectExceptAgrs()
    window.dataLayer.push({
      event: "cta.click",
      partner: { id: this.bodyTarget.getAttribute(partnerIdAttr) || "" },
      member: {
        logged: true,
        id: this.bodyTarget.getAttribute(memberIdAttr) || ""
      },
      page: {
        template: "accueil",
        subtemplate: ""
      },
      cta: {
        name: key || name || "",
        category: this.catalogType(currentElement.getAttribute(catalogTypeAttr)),
        subcategory: "liste produits",
        type: isImg ? "image" : "cta",
        from: "main"
      }
    })
  }

  // ######################## CaTALOG FAMILLE & PRO Page ########################
  displayCatalogPage(catalog) {
    let page
    if (catalog === "FAMILY") {
      page = {
        template: "catalogue famille",
        subtemplate: "grille"
      }
    } else if (catalog === "PRO") {
      page = {
        template: "catalogue pro",
        subtemplate: "grille"
      }
    }
    const boxes = this.catalogBoxTargets.map(box => box.getAttribute(tradKeyAttr))
    // send event
    this.clearObjectExceptAgrs()
    window.dataLayer.push({
      event: "cta.list_display",
      page: page,
      partner: { id: this.bodyTarget.getAttribute(partnerIdAttr) || "" },
      member: {
        logged: true,
        id: this.bodyTarget.getAttribute(memberIdAttr) || ""
      },
      list: {
        name: page.template,
        result_nb: boxes.length,
        page: 1,
        products: [],
        articles: [],
        granits: [],
        catalog: [],
        ctas: boxes,
        folders: [],
      }
    })
  }

  boxesClick(event) {
    event.preventDefault()
    const currentElement = event.currentTarget
    const key = currentElement.getAttribute(tradKeyAttr)
    let cat
    if (this.catalogBoxesBlockTarget.getAttribute(catalogAttr) === "FAMILY") {
      cat = "catalogue famille"
    } else if (this.catalogBoxesBlockTarget.getAttribute(catalogAttr) === "PRO") {
      cat = "catalogue pro"
    }
    // attributes category & subcategory
    const gtmObj = currentElement.getAttribute(adminObjectAttr)
    const gtmObjJson = this.isJSON(gtmObj) ? JSON.parse(gtmObj) : {}
    //
    const ctaObj = {
      ...gtmObjJson,
      name: key,
      type: "image",
      from: "grid"
    }
    //
    this.clearObjectExceptAgrs()
    window.dataLayer.push({
      event: "cta.click",
      partner: { id: this.bodyTarget.getAttribute(partnerIdAttr) || "" },
      member: {
        logged: true,
        id: this.bodyTarget.getAttribute(memberIdAttr) || ""
      },
      page: {
        template: cat,
        subtemplate: "grille"
      },
      cta: ctaObj
    })
    window.location.href = currentElement.href
  }

  // ######################## Product Preview (monuments & accessories) ########################
  selectProduct(event) {
    // if the click doesnt expect to open modal product
    if (event.target.getAttribute("data-open-preview") === "false" || event.currentTarget.getAttribute("data-open-preview") === "false") {
      return
    }
    //  var
    const isAccessory = event.currentTarget.getAttribute(productIsAccAttr) === "true"
    const fromComparator = event.currentTarget.getAttribute(fromComparatorAttr) === "true"
    const granit = event.currentTarget.getAttribute(productGranitAttr) || false
    const allGranitsModal = event.currentTarget.getAttribute(isProductAllGranitsModalAttr) || false
    const refProduct = event.currentTarget.getAttribute("id")
    //
    const productsVisible = Alpine.store("catalogs").productsVisible
    let product = productsVisible.find(product => product.reference === refProduct)
    if (!product) {
      if (fromComparator) {
        const monumentsToCompare = Alpine.store("catalogs").monumentsToCompare
        product = monumentsToCompare.find(product => product.reference === refProduct)
      } else {
        const products = Alpine.store("catalogs").products
        product = products.find(product => product.reference === refProduct)
      }
    }
    //
    let subtemplate
    if (fromComparator) {
      subtemplate = "comparateur"
    } else if (isAccessory) {
      subtemplate = !!allGranitsModal ? "accessoire - liste granits" : "liste accessoires" // eslint-disable-line no-extra-boolean-cast
    } else {
      subtemplate = !!allGranitsModal ? "produit - liste granits" : "liste produits" // eslint-disable-line no-extra-boolean-cast
    }

    // send event
    this.clearObjectExceptAgrs()
    window.dataLayer.push({
      event: "product.click",
      partner: { id: this.bodyTarget.getAttribute(partnerIdAttr) || "" },
      member: {
        logged: true,
        id: this.bodyTarget.getAttribute(memberIdAttr) || ""
      },
      catalog: this.catalogObj(),
      page: {
        template: this.catalogType(),
        subtemplate: subtemplate
      },
      product: this.productObj(product, isAccessory ? "accessoire" : false, granit, true, true)
    })
  }

  static displayProduct(product, granit = false, isAccessory = false) {
    const bodyTarget = this.bodyTarget || document.querySelector("[data-target='gtm.body']")
    // send event
    this.staticClearObjectExceptAgrs()
    window.dataLayer.push({
      event: "product.page_display",
      partner: { id: bodyTarget.getAttribute(partnerIdAttr) || "" },
      member: {
        logged: true,
        id: bodyTarget.getAttribute(memberIdAttr) || ""
      },
      catalog: granit ? null : this.staticCatalogObj(),
      page: {
        template: this.staticCatalogType(),
        subtemplate: isAccessory ? "accessoire" : "produit"
      },
      product: this.staticProductObj(product, isAccessory ? "accessoire" : false, granit, true, true)
    })
  }

  // display page all granits of one product
  static displayGranitsOfProduct(products, type, filter = [], offset = false) {
    const bodyTarget = this.bodyTarget || document.querySelector("[data-target='gtm.body']")
    const typeProduct = type === "ACC" ? "accessoire" : false
    let allProducts = []
    products.forEach((product, i) => {
      allProducts.push(this.staticProductObj(product, typeProduct, false, false, true))
    })
    // page attr
    let page = {
      template: this.staticCatalogType()
    }
    if (type === "ACC") {
      page.subtemplate = "accessoire - liste granits"
    } else {
      page.subtemplate = "produit - liste granits"
    }
    // send event
    this.staticClearObjectExceptAgrs()
    window.dataLayer.push({
      event: "product.list_display",
      partner: { id: bodyTarget.getAttribute(partnerIdAttr) || "" },
      member: {
        logged: true,
        id: bodyTarget.getAttribute(memberIdAttr) || ""
      },
      page: page,
      catalog: this.staticCatalogObj(),
      filter: filter,
      list: {
        name: "catalog",
        result_nb: allProducts.length,
        page: !offset ? 1 : Math.ceil(allProducts.length / offset),
        products: allProducts,
        articles: [],
        granits: [],
        catalog: [],
        ctas: [],
        folders: [],
      }
    })
  }

  // close page all granits
  static closeProductAllGranitsModal(isAccessory = false) {
    const bodyTarget = this.bodyTarget || document.querySelector("[data-target='gtm.body']")
    // send
    this.staticClearObjectExceptAgrs()
    window.dataLayer.push({
      event: "cta.click",
      partner: { id: bodyTarget.getAttribute(partnerIdAttr) || "" },
      member: {
        logged: true,
        id: bodyTarget.getAttribute(memberIdAttr) || ""
      },
      page: {
        template: this.staticCatalogType(),
        subtemplate: isAccessory ? "accessoire - liste granits" : "produit - liste granits"
      },
      cta: {
        name: isAccessory ? "accessoire - fermer liste granits" : "produit - fermer liste granits",
        category: this.staticCatalogType(),
        subcategory: isAccessory ? "liste accessoires" : "liste produits",
        type: "cta",
        from: "top"
      }
    })

    // retrack page obj
    this.staticUntrackedEvent({
      page: {
        template: this.staticCatalogType(),
        subtemplate: isAccessory ? "liste accessoires" : "liste produits"
      }
    })
  }

  categoriesClick(event) {
    const currentElement = event.currentTarget
    const categoryId = currentElement.getAttribute(nameAttr) // ID

    this.clearObjectExceptAgrs()
    window.dataLayer.push({
      event: "cta.click",
      partner: { id: this.bodyTarget.getAttribute(partnerIdAttr) || "" },
      member: {
        logged: true,
        id: this.bodyTarget.getAttribute(memberIdAttr) || ""
      },
      page: {
        template: this.catalogType(),
        subtemplate: "produit"
      },
      cta: {
        name: this.tradKey(categoryId),
        category: this.catalogType(),
        subcategory: "liste produits",
        type: "mot-clé",
        from: "bottom"
      }
    })
  }

  actionBtn(event) {
    const currentElement = event.currentTarget
    const name = currentElement.getAttribute(nameAttr)
    const btnAction = currentElement.getAttribute(btnSignificationAttr)
    const type = currentElement.getAttribute("type")
    const isAcc = type === "ACC"
    const isGranit = type === "GRANITE"
    //
    const product = Alpine.store("gpg").product // eslint-disable-line no-undef
    // Product object
    const productObj = isGranit ? {} : { product: this.productObj(product, isAcc ? "accessoire" : false, false, true) }
    //
    let catalog = {}
    if (!isGranit) {
      catalog = { catalog: this.catalogObj() }
    }
    //
    let category = this.catalogType("family")
    let subcategory = "produit"
    if (btnAction === "configurer") {
      category = isGranit ? this.catalogType("family") : "configurateur"
      subcategory = isAcc ? "accessoires" : (isGranit ? "liste produits" : "monument")
    } else if (btnAction === "tous-granits") {
      subcategory = isAcc ? "accessoire - liste granits" : "produit - liste granits"
    }

    this.clearObjectExceptAgrs()
    window.dataLayer.push({
      ...productObj,
      ...catalog,
      event: "cta.click",
      partner: { id: this.bodyTarget.getAttribute(partnerIdAttr) || "" },
      member: {
        logged: true,
        id: this.bodyTarget.getAttribute(memberIdAttr) || ""
      },
      page: {
        template: this.catalogType(),
        subtemplate: isAcc ? "accessoire" : (isGranit ? "granit" : "produit")
      },
      cta: {
        name: name,
        category: category,
        subcategory: subcategory,
        type: "cta",
        from: "bottom"
      }
    })
  }

  thumbnailClick(event) {
    const currentElement = event.currentTarget
    const isAcc = currentElement.getAttribute(productIsAccAttr) === "true"

    this.clearObjectExceptAgrs()
    window.dataLayer.push({
      event: "cta.click",
      partner: { id: this.bodyTarget.getAttribute(partnerIdAttr) || "" },
      member: {
        logged: true,
        id: this.bodyTarget.getAttribute(memberIdAttr) || ""
      },
      page: {
        template: this.catalogType(),
        subtemplate: isAcc ? "accessoire" : "produit"
      },
      cta: {
        name: event.currentTarget.getAttribute(nameAttr),
        category: this.catalogType(),
        subcategory: isAcc ? "accessoire" : "produit",
        type: "image",
        from: "thumbnails"
      }
    })
  }

  static closeProductPreviewModal(isAccessory = false) {
    const bodyTarget = this.bodyTarget || document.querySelector("[data-target='gtm.body']")
    // send
    this.staticClearObjectExceptAgrs()
    window.dataLayer.push({
      event: "cta.click",
      partner: { id: bodyTarget.getAttribute(partnerIdAttr) || "" },
      member: {
        logged: true,
        id: bodyTarget.getAttribute(memberIdAttr) || ""
      },
      page: {
        template: this.staticCatalogType(),
        subtemplate: isAccessory ? "accessoire" : "produit"
      },
      cta: {
        name: isAccessory ? "fermer l'accessoire" : "fermer le produit",
        category: this.staticCatalogType(),
        subcategory: isAccessory ? "liste accessoires" : "liste produits",
        type: "cta",
        from: "top"
      }
    })

    // retrack page obj
    this.staticUntrackedEvent({
      page: {
        template: this.staticCatalogType(),
        subtemplate: isAccessory ? "liste accessoires" : "liste produits"
      }
    })
  }

  // ######################## Catalog monument && accessories ########################
  static displayCatalogProducts(productsVisible, type, pageOffset) {
    const typeProduct = type === "ACC" ? "accessoire" : false
    const allProducts = productsVisible.map(product => this.staticProductObj(product, typeProduct, false, false, true))
    const bodyTarget = this.bodyTarget || document.querySelector("[data-target='gtm.body']")
    // page attr
    let page = {
      template: this.staticCatalogType()
    }
    let comparatorObj = {}
    if (type === "ACC") {
      page.subtemplate = "liste accessoires"
    } else if (type === "MNT") {
      page.subtemplate = "liste produits"
      comparatorObj = { comparator: this.staticComparatorObj() }
    }
    // get tags
    let filter = []
    Alpine.store("catalogs").tags.forEach(tag => {
      const existOnCategories = tag.id !== this.staticTradKey(tag.id)
      filter.push({ name: existOnCategories ? "keyword" : "search", value: this.staticTradKey(tag.id) })
    })
    // END get tags
    // send event
    this.staticClearObjectExceptAgrs()
    window.dataLayer.push({
      ...comparatorObj,
      event: "product.list_display",
      partner: { id: bodyTarget.getAttribute(partnerIdAttr) || "" },
      filter: filter,
      member: {
        logged: true,
        id: bodyTarget.getAttribute(memberIdAttr) || ""
      },
      catalog: this.staticCatalogObj(),
      page: page,
      list: {
        name: "catalog",
        result_nb: allProducts.length,
        page: Math.ceil(allProducts.length / pageOffset),
        products: allProducts,
        articles: [],
        granits: [],
        ctas: [],
        folders: [],
      }
    })
  }

  // _________________________ Monuments && Accessory filter (search) _________________________
  static displayResultSearch(products, filter = [], isAccessory, withoutFilter = false) {
    // Add filter object
    if (withoutFilter === true) {
      filter = GtmController.otherFilters([], isAccessory, false, "color", "category", "granit", "searchKey", "layout")
    }
    // gtm type product
    const type = isAccessory ? "accessoire" : false
    // END gtm type product
    let comparatorObj = {}
    if (!isAccessory) {
      comparatorObj = { comparator: this.staticComparatorObj() }
    }
    const resProducts = products.map(product => this.staticProductObj(product, type, false, false, true))
    const bodyTarget = this.bodyTarget || document.querySelector("[data-target='gtm.body']")
    // send event
    this.staticClearObjectExceptAgrs()
    window.dataLayer.push({
      ...comparatorObj,
      event: "product.list_display",
      partner: { id: bodyTarget.getAttribute(partnerIdAttr) || "" },
      member: {
        logged: true,
        id: bodyTarget.getAttribute(memberIdAttr) || ""
      },
      catalog: this.staticCatalogObj(),
      page: {
        template: this.staticCatalogType(),
        subtemplate: `liste ${isAccessory ? "accessoires" : "produits"}`
      },
      filter: filter,
      list: {
        name: "catalog",
        result_nb: resProducts.length,
        page: 1,
        products: resProducts,
        articles: [],
        granits: [],
        ctas: [],
        folders: [],
      }
    })
  }

  static displayResultSearchByGranit(filtredProducts, granit, isAccessory) {
    let filter = granit !== null ? [{ name: "granit", value: `granit_${granit}` }] : []

    // add other filters
    filter = this.otherFilters(filter, isAccessory, false, "color", "category", "searchKey", "layout")

    this.displayResultSearch(filtredProducts, filter, isAccessory)
  }

  static displayResultSearchByCategory(filtredProducts, categories, isAccessory) {
    let filter = []
    if (isAccessory) {
      if (categories !== null) {
        filter = [{ name: "category", value: categories }]
      }
    } else {
      filter = categories.map(category => {
        const existOnCategories = category.id !== this.staticTradKey(category.id)
        return { name: existOnCategories ? "keyword" : "search", value: this.staticTradKey(category.id) }
      })
    }

    // add other filters
    filter = this.otherFilters(filter, isAccessory, false, "color", "granit", "searchKey", "layout")

    this.displayResultSearch(filtredProducts, filter, isAccessory)
  }

  static displayResultSearchByColor(filtredProducts, color, isAccessory) {
    let filter = []
    if (color !== null) {
      let colorNames
      if (color.toString().includes("/")) {
        let colors = color.split("/")
        colorNames = colors.map(color => this.staticGetColorName(parseInt(color))).join("/")
      } else {
        colorNames = this.staticGetColorName(parseInt(color))
      }
      filter = [{ name: "color", value: colorNames }]
    }

    // add other filters
    filter = this.otherFilters(filter, isAccessory, false, "category", "granit", "searchKey", "layout")

    this.displayResultSearch(filtredProducts, filter, isAccessory)
  }

  static displayResultSearchBylayout(filtredProducts, layout, isAccessory = false) {
    let filter = []
    if (layout) {
      filter = [{ name: "layout", value: layout.keyword }]
    }

    // add other filters
    filter = this.otherFilters(filter, isAccessory, false, "color", "category", "granit", "searchKey")

    this.displayResultSearch(filtredProducts, filter, isAccessory)
  }

  static displayResultSearchWithInput(filtredProducts, key, isAccessory) {
    let filter = []
    if (isAccessory) {
      const searchkeys = Alpine.store("catalogs").searchkeys
      searchkeys.forEach(searchkey => {
        const existOnCategories = searchkey !== this.staticTradKey(searchkey)
        filter.push({ name: existOnCategories ? "keyword" : "search", value: this.staticTradKey(searchkey) })
      })
    } else {
      const existOnCategories = key !== this.staticTradKey(key)
      filter.push({ name: existOnCategories ? "keyword" : "search", value: this.staticTradKey(key) })
    }

    // add other filters
    filter = this.otherFilters(filter, isAccessory, key, "category", "color", "granit", "layout")

    this.displayResultSearch(filtredProducts, filter, isAccessory)
  }

  static otherFilters(filter, isAccessory, exceptKey = false, ...args) {
    args.forEach(arg => {
      if (arg === "color") {
        const color = Alpine.store("catalogs").color
        const colorName = this.staticGetColorName(color) || null
        if (colorName !== null) {
          filter.push({ name: "color", value: colorName })
        }
      }
      if (arg === "category") {
        if (isAccessory) {
          const category = Alpine.store("catalogs").category || null
          if (category !== null) {
            filter.push({ name: "category", value: category })
          }
        } else {
          const tags = Alpine.store("catalogs").tags
          tags
            .filter(tag => tag.type !== "layout")
            .forEach(tag => {
              if (exceptKey === tag.id) return
              const existOnCategories = tag.id !== this.staticTradKey(tag.id)
              filter.push({ name: existOnCategories ? "keyword" : "search", value: this.staticTradKey(tag.id) })
            })
        }
      }
      if (arg === "granit") {
        const granit = Alpine.store("catalogs").granit
        if (granit !== null) {
          const granitValue = granit.substring(0, 2)
          filter.push({ name: "granit", value: `granit_${granitValue}` })
        }
      }
      if (!isAccessory && arg === "layout") {
        const layouts = Alpine.store("catalogs").filtredLayouts
        const selectedLayoutId = Alpine.store("catalogs").layout
        if (selectedLayoutId) {
          const selectedLayout = layouts.find(layoutObj => layoutObj.id === selectedLayoutId)
          filter.push({ name: "layout", value: selectedLayout.keyword })
        }
      }
      if (isAccessory && arg === "searchKey") {
        const searchkeys = Alpine.store("catalogs").searchkeys
        searchkeys.forEach(searchkey => {
          filter.push({ name: "search", value: this.staticTradKey(searchkey) })
        })
      }
    })
    return filter
  }

  // _________________________ Single Product Objects _____________________________
  static staticProductObj(product, type = false, granit = false, isSingleProduct = false, withPrice = false, comparatorPrice = false) {
    const granitRef = granit || (product.gm || "")
    const granitList = Alpine.store("catalogs").granites
    const gm = granitList.find(g => g.reference === granitRef)
    const gs = granitList.find(g => g.reference === product.gs)

    let id, name, reference
    if (type === "accessoire") {
      id = product.name
      reference = product.reference
      if (isSingleProduct) {
        name = `${product.reference} ${product.description}`
      } else {
        name = this.staticTradKey(product.reference)
      }
    } else {
      id = product.preconfId
      name = product.name
      reference = product.productId
    }
    // With price attributes
    let priceObj = comparatorPrice ? { price_from: "", price_to: "" } : { price_from: "", buy_from: "" }
    if (comparatorPrice && product.price) {
      priceObj = {
        price_from: product.price.min || "",
        price_to: product.price.max || "",
      }
    } else if (withPrice && product.prices) {
      priceObj = {
        price_from: product.prices.selling ? Math.ceil(product.prices.selling) : "",
        buy_from: product.prices.discounted ? Math.ceil(product.prices.discounted) : "",
      }
    }
    //
    return {
      ...priceObj,
      id: id || "",
      name: name || "",
      reference: reference || "",
      granits: [
        gm ? gm.name : "",
        gs ? gs.name : null,
      ],
      couleurs: gm ? [this.staticGetColorName(gm.color)] : [],
      granit_main: gm ? gm.name : "",
      flower_button: "", //
      v360_button: "",
      types: type || this.staticGetType(product.keys) || ""
    }
  }

  productObj(product, type = false, granit = false, isSingleProduct = false, withPrice = false, comparatorPrice = false) {
    return GtmController.staticProductObj(product, type, granit, isSingleProduct, withPrice, comparatorPrice)
  }

  /**
   * return
   *   'inhumation' ou 'crémation' ou  'mixte' ou 'accessoire' ou 'semelle' ou  'placage' (semelle et placage pour config3d)
   * */
  static staticGetType(keys) {
    const keywordslist = Alpine.store("catalogs").allkeywords
    const keysName = keys.map(key => keywordslist.find(k => k.id === key)?.name)
    if (keysName.includes("Inhumation")) {
      return "inhumation"
    } else if (keysName.includes("Crémation")) {
      return "crémation"
    } else if (keysName.includes("Mixtes")) {
      return "mixte"
    }
    return false
  }

  // ######################## RESET FILTER (monuments & accessories & granits) ########################
  static resetFilter(currentTarget, isAccessory, isGranit, isProductGranits) {
    const bodyTarget = this.bodyTarget || document.querySelector("[data-target='gtm.body']")
    const key = currentTarget.getAttribute(tradKeyAttr)
    if (isProductGranits) {
      const productIsAcc = Alpine.store("productGranits").productIsAcc
      isAccessory = productIsAcc
    }
    // Send event
    this.staticClearObjectExceptAgrs()
    window.dataLayer.push({
      event: "cta.click",
      partner: { id: bodyTarget.getAttribute(partnerIdAttr) || "" },
      member: {
        logged: true,
        id: bodyTarget.getAttribute(memberIdAttr) || ""
      },
      cta: {
        name: key,
        category: this.staticCatalogType(),
        subcategory: isGranit
          ? "liste granits"
          : (isAccessory
              ? "liste accessoires"
              : "liste produits"),
        type: "cta",
        from: "filter"
      }
    })
  }

  // ######################## Catalog granits ########################
  static displayCatalogGranits(granits) {
    const allGranits = granits.map(granit => this.staticGranitObj(granit))
    const bodyTarget = this.bodyTarget || document.querySelector("[data-target='gtm.body']")
    // send event
    this.staticClearObjectExceptAgrs()
    window.dataLayer.push({
      event: "granit.list_display",
      partner: { id: bodyTarget.getAttribute(partnerIdAttr) || "" },
      member: {
        logged: true,
        id: bodyTarget.getAttribute(memberIdAttr) || ""
      },
      page: {
        template: this.staticCatalogType(),
        subtemplate: "liste granits"
      },
      catalog: this.staticCatalogObj(),
      filter: [],
      list: {
        name: "catalog",
        result_nb: allGranits.length,
        page: 1,
        products: [],
        articles: [],
        granits: allGranits,
        catalog: [],
        ctas: [],
        folders: [],
      }
    })
  }

  // _________________________ Granits filter (search, color, origin) _________________________
  static displayGranitResultSearch(granits, filter = []) {
    const bodyTarget = this.bodyTarget || document.querySelector("[data-target='gtm.body']")
    const resGranits = granits.map(g => this.staticGranitObj(g))
    // Set name of color
    filter.forEach(f => {
      if (f.name === "color") {
        f.value = this.staticGetColorName(f.value)
      }
      if (f.name === "name") {
        f.name = "search"
      }
    })
    // send event
    this.staticClearObjectExceptAgrs()
    window.dataLayer.push({
      event: "granit.list_display",
      partner: { id: bodyTarget.getAttribute(partnerIdAttr) || "" },
      member: {
        logged: true,
        id: bodyTarget.getAttribute(memberIdAttr) || ""
      },
      page: {
        template: this.staticCatalogType(),
        subtemplate: "liste granits"
      },
      catalog: this.staticCatalogObj(),
      filter: filter,
      list: {
        name: "catalog",
        result_nb: resGranits.length,
        page: 1,
        products: [],
        articles: [],
        granits: resGranits,
        catalog: [],
        ctas: [],
        folders: [],
      }
    })
  }

  // _________________________ Single Granit Objects _____________________________
  static staticGranitObj(granit) {
    return {
      id: granit.reference, // empty
      name: granit.name || "",
      countries: [granit.origin || ""],
      reference: granit.reference || "",
      couleurs: [this.staticGetColorName(granit.color)],
      quality: "" // empty
    }
  }

  granitObj(granit) {
    return GtmController.staticGranitObj(granit)
  }

  // ######################## Granit Preview ########################
  selectGranit(event) {
    //  var
    const refGranit = event.currentTarget.getAttribute("id")
    const granits = Alpine.store("granit").granits
    //
    const granit = granits.find(granits => granits.reference === refGranit)
    // send event
    this.clearObjectExceptAgrs()
    window.dataLayer.push({
      event: "granit.click",
      partner: { id: this.bodyTarget.getAttribute(partnerIdAttr) || "" },
      member: {
        logged: true,
        id: this.bodyTarget.getAttribute(memberIdAttr) || ""
      },
      catalog: this.catalogObj(),
      page: {
        template: this.catalogType(),
        subtemplate: "liste granits"
      },
      granit: this.granitObj(granit)
    })
  }

  static displayGranit(granit) {
    const bodyTarget = this.bodyTarget || document.querySelector("[data-target='gtm.body']")
    // send event
    this.staticClearObjectExceptAgrs()
    window.dataLayer.push({
      event: "granit.page_display",
      partner: { id: bodyTarget.getAttribute(partnerIdAttr) || "" },
      member: {
        logged: true,
        id: bodyTarget.getAttribute(memberIdAttr) || ""
      },
      page: {
        template: this.staticCatalogType(),
        subtemplate: "granit"
      },
      catalog: this.staticCatalogObj(),
      granit: this.staticGranitObj(granit)
    })
  }

  // ######################## Color ########################
  static staticGetColorName(id) {
    const colors = Alpine.store("granit").colors
    const color = colors.find(c => c.id === id)
    return color && color.internalId ? `granit_color_${color.internalId}` : id
  }

  getColorName(id) {
    return GtmController.staticGetColorName(id)
  }

  // ######################## News ########################
  static staticDisplayNewsPage() {
    const bodyTarget = this.bodyTarget || document.querySelector("[data-target='gtm.body']")
    const articlesElements = this.newsArticleTargets || document.querySelectorAll("[data-target='gtm.newsArticle']")
    let filter = []
    // Filter => check link
    const newsTags = this.newsTagTargets || document.querySelectorAll("[data-target='gtm.newsTag']")
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    // Tags
    const tagParam = urlParams.get("tag")
    newsTags.forEach(tagElem => {
      if (tagElem.getAttribute("data-tagid") === tagParam) {
        filter.push({ name: "tag", value: tagElem.textContent })
      }
    })
    // END Tags
    // Input search
    const searchParam = urlParams.get("q")
    if (searchParam) {
      filter.push({ name: "search", value: searchParam })
    }
    // END Input search
    // END check link
    let allArticles = []
    articlesElements.forEach(article => allArticles.push(this.staticNewsObj(article)))
    // send event
    this.staticClearObjectExceptAgrs()
    window.dataLayer.push({
      event: "article.list_display",
      page: {
        template: "actualités",
        subtemplate: "liste actualités"
      },
      partner: { id: bodyTarget.getAttribute(partnerIdAttr) || "" },
      member: {
        logged: true,
        id: bodyTarget.getAttribute(memberIdAttr) || ""
      },
      filter: filter,
      list: {
        name: "actualités",
        result_nb: allArticles.length,
        page: articlesElements.length / 10,
        products: [],
        articles: allArticles,
        granits: [],
        catalog: [],
        ctas: [],
        folders: [],
      }
    })
  }

  displayNewsPage() {
    GtmController.staticDisplayNewsPage()
  }

  // ______________select article _________________________
  selectArticle() {
    // event.preventDefault()
    // // init
    // const articleId = event.currentTarget.getAttribute("data-id")
    // const articleElement = document.querySelector(`[${newsIdAttr}='${articleId}']`)
    // // send event
    // this.clearObjectExceptAgrs()
    // window.dataLayer.push({
    //   event: "article.click",
    //   partner: { id: this.bodyTarget.getAttribute(partnerIdAttr) || "" },
    //   member: {
    //     logged: true,
    //     id: this.bodyTarget.getAttribute(memberIdAttr) || ""
    //   },
    //   page: {
    //     template: "actualités",
    //     subtemplate: "liste actualités"
    //   },
    //   article: this.newsObj(articleElement)
    // })
    // // redirect

    // window.location.href = event.currentTarget.href
    this.clearObjectExceptAgrs()
    const articleId = document.getElementById("news-container").getAttribute("data-gtm-news-id-param")
    const articleElement = document.querySelector(`[${newsIdAttr}='${articleId}']`)
    window.dataLayer.push({
      event: "article.click",
      partner: { id: this.bodyTarget.getAttribute(partnerIdAttr) || "" },
      member: {
        logged: true,
        id: this.bodyTarget.getAttribute(memberIdAttr) || ""
      },
      page: {
        template: "actualités",
        subtemplate: "liste actualités"
      },
      article: this.newsObj(articleElement)
    })
  }

  // ______________ single article page  _________________________
  displaySingleNews() {
    // send event
    this.clearObjectExceptAgrs()
    window.dataLayer.push({
      event: "article.page_display",
      partner: { id: this.bodyTarget.getAttribute(partnerIdAttr) || "" },
      member: {
        logged: true,
        id: this.bodyTarget.getAttribute(memberIdAttr) || ""
      },
      page: {
        template: "actualités",
        subtemplate: "liste actualités"
      },
      article: this.newsObj(this.newsArticlePageTarget)
    })
  }

  // ______________ filter news by tag(category)  _________________________
  filterNews(event) {
    event.preventDefault()
    this.clearObjectExceptAgrs()
    window.dataLayer.push({
      event: "cta.click",
      partner: { id: this.bodyTarget.getAttribute(partnerIdAttr) || "" },
      member: {
        logged: true,
        id: this.bodyTarget.getAttribute(memberIdAttr) || ""
      },
      page: {
        template: "actualités",
        subtemplate: "liste actualités"
      },
      cta: {
        name: event.currentTarget.textContent || "",
        category: "actualités",
        subcategory: "liste actualités",
        type: "cta",
        from: "liste actualités"
      }
    })
    // redirect
    window.location.href = event.currentTarget.href
  }

  // ________ NEWS OBJECT __________
  static staticNewsObj(element) {
    return {
      id: element.getAttribute(newsIdAttr),
      type: element.getAttribute(newsTypeAttr),
      tags: element.getAttribute(newsTagsAttr).split(","),
      title: element.getAttribute(newsTitleAttr) || "",
      text: element.getAttribute(newsTextAttr) || "",
      link: element.getAttribute(newsLinkAttr) || "",
      image: element.getAttribute(newsImglinkAttr) || "",
      videoId: element.getAttribute(newsVideoidAttr) || "",
      videoType: element.getAttribute(newsVideotypeAttr) || ""
    }
  }

  newsObj(element) {
    return GtmController.staticNewsObj(element)
  }

  // ###################### GET TRAD KEY ###################
  static staticTradKey(id) {
    const keywords = Alpine.store("catalogs").allkeywords
    const categories = Alpine.store("catalogs").categories
    const valueKey = keywords.find(k => k.id === id)
    const valueCatego = categories.find(category => category.id === id)
    id = valueCatego ? (valueCatego.keyword ? valueCatego.keyword : valueCatego.name) : id
    return valueKey ? (valueKey.keyword ? valueKey.keyword : valueKey.name) : id
  }

  tradKey(id) {
    return GtmController.staticTradKey(id)
  }

  // ###################### Filter toggle ###################
  static filterToggle(display, pageType) {
    const bodyTarget = this.bodyTarget || document.querySelector("[data-target='gtm.body']")
    // Get page object
    let page = {
      template: this.staticCatalogType()
    }
    switch (pageType) {
      case "granite":
        page.subtemplate = "liste granits"
        break
      case "catalogs":
        page.subtemplate = "liste produits"
        break
      case "accessories":
        page.subtemplate = "liste accessoires"
        break
    }
    // END Get page object
    this.staticClearObjectExceptAgrs()
    window.dataLayer.push({
      event: "filters.click",
      partner: { id: bodyTarget.getAttribute(partnerIdAttr) || "" },
      member: {
        logged: true,
        id: bodyTarget.getAttribute(memberIdAttr) || ""
      },
      page: page,
      filters: {
        displayed: display // boolean
      }
    })
  }

  // ###################### comparateur events ###################
  static comparatorToggle() {
    const bodyTarget = this.bodyTarget || document.querySelector("[data-target='gtm.body']")
    this.staticClearObjectExceptAgrs()
    window.dataLayer.push({
      event: "comparator.click",
      partner: { id: bodyTarget.getAttribute(partnerIdAttr) || "" },
      member: {
        logged: true,
        id: bodyTarget.getAttribute(memberIdAttr) || ""
      },
      page: {
        template: this.staticCatalogType(),
        subtemplate: "liste produits"
      },
      catalog: this.staticCatalogObj(),
      comparator: this.staticComparatorObj()
    })
  }

  // display modal comparator AND filter by color on comparator
  static displayComparatorModal() {
    const bodyTarget = this.bodyTarget || document.querySelector("[data-target='gtm.body']")
    // products
    const monumentsToCompare = Alpine.store("catalogs").monumentsToCompare // eslint-disable-line no-undef
    const products = monumentsToCompare.length > 0 ? monumentsToCompare.map(product => this.staticProductObj(product, false, false, false, false, true)) : []
    // filter
    const comparatorColor = Alpine.store("catalogs").comparatorColor // eslint-disable-line no-undef
    const comparatorLayout = Alpine.store("catalogs").comparatorLayout
    let filter = []
    if (!(comparatorColor === null || comparatorColor === 0)) {
      filter.push({ name: "color", value: this.staticGetColorName(comparatorColor) })
    }
    if (!(comparatorLayout === null || comparatorLayout === "")) {
      const layouts = Alpine.store("catalogs").layouts
      const selectedLayout = layouts.find(l => l.id === comparatorLayout)
      filter.push({ name: "layout", value: selectedLayout ? selectedLayout.keyword : comparatorLayout })
    }
    // send event
    this.staticClearObjectExceptAgrs()
    window.dataLayer.push({
      event: "product.list_display",
      partner: { id: bodyTarget.getAttribute(partnerIdAttr) || "" },
      member: {
        logged: true,
        id: bodyTarget.getAttribute(memberIdAttr) || ""
      },
      page: {
        template: this.staticCatalogType(),
        subtemplate: "comparateur"
      },
      filter: filter,
      list: {
        name: "comparateur",
        result_nb: products.length,
        page: 1,
        products: products,
        articles: [],
        granits: [],
        catalog: [],
        ctas: [],
        folders: [],
      }
    })
  }

  static closeComparatorModal() {
    const bodyTarget = this.bodyTarget || document.querySelector("[data-target='gtm.body']")
    // send
    this.staticClearObjectExceptAgrs()
    window.dataLayer.push({
      event: "cta.click",
      partner: { id: bodyTarget.getAttribute(partnerIdAttr) || "" },
      member: {
        logged: true,
        id: bodyTarget.getAttribute(memberIdAttr) || ""
      },
      page: {
        template: this.staticCatalogType(),
        subtemplate: "comparateur"
      },
      cta: {
        name: "fermer le comparateur",
        category: this.staticCatalogType(),
        subcategory: "liste produits",
        type: "cta",
        from: "top"
      }
    })

    // retrack page obj
    this.staticUntrackedEvent({
      page: {
        template: this.staticCatalogType(),
        subtemplate: "liste produits"
      }
    })
  }

  // ____________ comparateur object _____________
  static staticComparatorObj() {
    const comparatorPopup = Alpine.store("catalogs").comparatorPopup
    const comparatorPopupHead = Alpine.store("catalogs").comparatorPopupHead
    const monumentsToCompare = Alpine.store("catalogs").monumentsToCompare
    return {
      displayed: comparatorPopup,
      size: comparatorPopup
        ? (comparatorPopupHead
            ? "light"
            : "full")
        : null,
      products: monumentsToCompare.length > 0
        ? monumentsToCompare.map(product => this.staticProductObj(product, false, false, false, false, true))
        : null
    }
  }

  comparatorObj() {
    return GtmController.staticComparatorObj()
  }

  // ###################### comparateur input events (ajout et suppression) ###################
  static addAndRemoveFromComparator(added, fromComparator = false, product) {
    const bodyTarget = this.bodyTarget || document.querySelector("[data-target='gtm.body']")
    const fromComparatorModal = document.querySelector("[data-target='gtm.modalComparator']")
    // List & filtre object
    // // products
    const monumentsToCompare = Alpine.store("catalogs").monumentsToCompare
    const products = monumentsToCompare.length > 0 ? monumentsToCompare.map(product => this.staticProductObj(product, false, false, false, false, true)) : []
    // // filter
    const comparatorColor = Alpine.store("catalogs").comparatorColor
    const comparatorLayout = Alpine.store("catalogs").comparatorLayout
    let filter = []
    if (!(comparatorColor === null || comparatorColor === 0)) {
      filter.push({ name: "color", value: this.staticGetColorName(comparatorColor) })
    }
    if (!(comparatorLayout === null || comparatorLayout === "")) {
      const layouts = Alpine.store("catalogs").layouts
      const selectedLayout = layouts.find(l => l.id === comparatorLayout)
      filter.push({ name: "layout", value: selectedLayout ? selectedLayout.keyword : comparatorLayout })
    }
    const listObj = !fromComparatorModal
      ? {}
      : {
          filter: filter,
          list: {
            name: "comparateur",
            result_nb: products.length,
            page: 1,
            products: products,
            articles: [],
            granits: [],
            catalog: [],
            ctas: [],
            folders: [],
          }
        }
    // comparator object
    const comparatorObj = fromComparatorModal ? {} : { comparator: this.staticComparatorObj() }
    // send
    this.staticClearObjectExceptAgrs()
    window.dataLayer.push({
      ...listObj,
      ...comparatorObj,
      event: "cta.click",
      partner: { id: bodyTarget.getAttribute(partnerIdAttr) || "" },
      member: {
        logged: true,
        id: bodyTarget.getAttribute(memberIdAttr) || ""
      },
      page: {
        template: this.staticCatalogType(),
        subtemplate: fromComparatorModal ? "comparateur" : "liste produits"
      },
      cta: {
        name: `${added ? "Ajouter au" : "Supprimer du"} comparateur`,
        category: this.staticCatalogType(),
        subcategory: fromComparatorModal ? "comparateur" : "liste produits",
        type: "cta",
        from: fromComparator ? "comparateur" : "main"
      },
      catalog: this.staticCatalogObj(),
      product: this.staticProductObj(product, false, false, true, true, fromComparatorModal)
    })

    if (fromComparatorModal && !added && products.length === 1) {
      // retrack page obj
      this.staticUntrackedEvent({
        page: {
          template: this.staticCatalogType(),
          subtemplate: "liste produits"
        }
      })
    }
  }

  static comparatorRemoveAllOrCompare(tradKey, to = "liste produits") {
    const bodyTarget = this.bodyTarget || document.querySelector("[data-target='gtm.body']")

    let comparatorObj = {}
    if (tradKey === "extranet.comparator.comparator_bar_button") {
      comparatorObj = { comparator: this.staticComparatorObj() }
    }
    // send
    this.staticClearObjectExceptAgrs()
    window.dataLayer.push({
      ...comparatorObj,
      event: "cta.click",
      partner: { id: bodyTarget.getAttribute(partnerIdAttr) || "" },
      member: {
        logged: true,
        id: bodyTarget.getAttribute(memberIdAttr) || ""
      },
      page: {
        template: this.staticCatalogType(),
        subtemplate: "liste produits"
      },
      cta: {
        name: tradKey,
        category: this.staticCatalogType(),
        subcategory: to,
        type: "cta",
        from: "comparateur"
      }
    })
  }

  // ####################### Breadcrumb #######################
  breadcrumbClick(event) {
    event.preventDefault()
    const linkedElement = event.currentTarget // Tag A
    const name = linkedElement.getAttribute(tradKeyAttr) // translation key
    const destination = linkedElement.getAttribute(destinationAttr)

    let subcategory = ""
    if (name === "extranet.pages.page_catalogfamily") {
      subcategory = "grille"
    }

    this.clearObjectExceptAgrs()
    window.dataLayer.push({
      event: "cta.click",
      partner: { id: this.bodyTarget.getAttribute(partnerIdAttr) || "" },
      member: {
        logged: true,
        id: this.bodyTarget.getAttribute(memberIdAttr) || ""
      },
      cta: {
        name: name,
        category: destination,
        subcategory: subcategory,
        type: "lien",
        from: "breadcrumbs"
      }
    })
    window.location.href = linkedElement.href
  }

  // ####################### Change Organization #######################
  static clickOnSelectOrganization(isOpen) {
    const bodyTarget = this.bodyTarget || document.querySelector("[data-target='gtm.body']")
    this.staticClearObjectExceptAgrs()
    window.dataLayer.push({
      event: "cta.click",
      partner: { id: bodyTarget.getAttribute(partnerIdAttr) || "" },
      member: {
        logged: true,
        id: bodyTarget.getAttribute(memberIdAttr) || ""
      },
      cta: {
        name: `${isOpen ? "open" : "close"} organization select`,
        category: "",
        subcategory: "",
        type: "cta",
        from: "topnavbar"
      }
    })
  }

  changeOrganization(event) {
    event.preventDefault()
    this.clearObjectExceptAgrs()
    window.dataLayer.push({
      event: "cta.click",
      partner: { id: this.bodyTarget.getAttribute(partnerIdAttr) || "" },
      member: {
        logged: true,
        id: this.bodyTarget.getAttribute(memberIdAttr) || ""
      },
      cta: {
        name: "choose organization",
        category: "",
        subcategory: "",
        type: "cta",
        from: "topnavbar"
      }
    })
  }

  // ####################### Catalog Object #######################
  static staticCatalogObj() {
    const catalogs = this.subnavbarTargets || document.querySelectorAll("[data-target='gtm.subnavbar']")
    let catalogObject = {
      id: "",
      name: ""
    }

    catalogs.forEach(catalog => {
      if (catalog.getAttribute(activeItemAttr) === "true") {
        catalogObject = {
          id: catalog.getAttribute(idAttr),
          name: catalog.getAttribute(nameAttr)
        }
      }
    })

    return catalogObject
  }

  catalogObj() {
    return GtmController.staticCatalogObj()
  }

  // ####################### Close MODAL #######################
  static closeModal() {
    if (document.querySelector("[data-target='gtm.modalComparator']")) { // comparator
      this.closeComparatorModal()
    } else if (document.querySelector("[data-target='gtm.modalMnt']")) { // Monument
      this.closeProductPreviewModal()
    } else if (document.querySelector("[data-target='gtm.modalAcc']")) { // Accessory
      this.closeProductPreviewModal(true)
    } else if (document.querySelector("[data-target='gtm.modalMonumentGranits']")) { // Monument - all Granits
      this.closeProductAllGranitsModal()
    } else if (document.querySelector("[data-target='gtm.modalAccessoryGranits']")) { // Accessory - all Granits
      this.closeProductAllGranitsModal(true)
    }
  }

  // ####################### Clear the previous object #######################
  static staticClearObjectExceptAgrs(...args) {
    let obj = {
      filter: null,
      list: null,
      cta: null,
      product: null,
      comparator: null,
      filters: null,
      article: null,
      granit: null,
      login: null,
      error: null,
      catalog: null
    }
    // Attributs to not reset
    args.forEach(arg => {
      delete obj[arg]
    })
    // Send data
    window.dataLayer.push(obj)
  }

  clearObjectExceptAgrs(...args) {
    GtmController.staticClearObjectExceptAgrs(...args)
  }

  // ####################### untracked events  #######################
  static staticUntrackedEvent(obj) {
    // Send event
    this.staticClearObjectExceptAgrs()
    window.dataLayer.push({
      ...obj,
      event: "untracked"
    })
  }

  untrackedEvent(obj) {
    GtmController.staticUntrackedEvent(obj)
  }

  // check if var is Json
  isJSON(str) {
    try {
      JSON.parse(str)
      return true
    } catch (e) {
      return false
    }
  }
}