import { Controller } from "stimulus"
import "../lib/select2"

export default class extends Controller {
    keys = Alpine.store("catalogs").searchkeys
    connect() {
      // $('.js-example-basic-multiple').select2()
      $("#search").select2({ // eslint-disable-line no-undef
        placeholder: $("#search").attr("placeholder"), /* eslint-env jquery */
        minimumResultsForSearch: 15,
        scrollAfterSelect: false,
        tags: true,
        templateResult: this.formatState
      }).val(this.keys).trigger("change") // eslint-disable-line no-undef
      const select = document.getElementById("search")
      const keywordsLink = select ? select.getAttribute("keywords") : false

      $("#search").on("change", function (e) { // eslint-disable-line no-undef
        // if ($(this).val().length === 0) {
        //   e.stopPropagation()
        //   e.preventDefault()
        // }
        if ($(this).val().includes("more")) {
          $(this).val($(this).val().filter(value => value !== "more"))
          keywordsLink && document.body.modalController.open(keywordsLink)
          const modalInfo = document.getElementsByClassName("modal")[0]
          modalInfo.classList.add("modal-center")
          e.preventDefault()
          return
        }
        const elementACC = document.getElementById("accessories-catalog")
        const elementStock = document.getElementById("stocks")
        if (elementACC) {
          document.getElementsByClassName("select2-selection__choice__remove").forEach(element => {
            element.title = ""
          })
          Alpine.store("catalogs").onSearchACC($(this).val(), e, true)
        } else if (elementStock) {
          let searchVal = $(this).val()
          if ($(this).val().length > 0) {
            Alpine.store("stock").onSearch({ type: "keys", value: searchVal })
          }
        } else {
          // const = .trigger("change")
          document.getElementsByClassName("select2-selection__choice__remove").forEach(element => {
            element.title = ""
          })
          const values = Alpine.store("catalogs").tags.filter(tag => tag.type !== "keyword").map(tag => tag.id) // eslint-disable-line no-undef
          Alpine.store("catalogs").onSearch(values.concat($(this).val()), false, false, e, true) // eslint-disable-line no-undef
        }
        document.querySelector(".search").classList.remove("hidden2")
      })
      document.querySelector(".search").classList.remove("hidden2")

      $(".select2-search__field").keydown(function (e) { // eslint-disable-line no-undef
        if (e.keyCode === 8 || e.keyCode === 46) {
          if ($(this).val().length === 0) {
            e.stopPropagation()
            e.preventDefault()
          }
        }
      })
    }

    formatState(state) {
      if (!state.id) {
        return state.text.split("<img")[0]
      }
      const $state = $( // eslint-disable-line no-undef
        `<span  class='keyword-list' title= '${state.text.split("<img")[0]}' >` + state.text + "</span>"
      )
      return $state
    }
}
