import { Controller } from "stimulus"
import { get, post } from "../lib/request"
import { createSelect } from "./select_newstyle_controller"
import exec from "../lib/exec"
import GtmController from "./gtm_controller"
import { disableLayoutSelect } from "./comparator_controller"

export default class extends Controller {
  static targets = ["container", "contents", "body", "title"];

  connect() {
    this.originalHTML = this.bodyTarget.innerHTML
    this.currentController = null
    this.execScripts = this.data.get("exec-scripts")
    this.element.modalController = this
    this._currentOptions = {}
    this.admin = null
    document.querySelector(".modal").addEventListener("click", function (e) {
      if (document.getElementById("keywordlist")) {
        const box = e.target.classList.contains("keywords-list")
        const secondbox = e.target.classList.contains("modal-contents")
        if (!secondbox && !box) {
          document.body.modalController.close()
        }
      }
    })
  }

  disconnect() {
    this.close()
    this.element.modalController = null
  }

  async open(templatePath, options = {}) {
    if (typeof Alpine !== "undefined") {
      Alpine.store("productGranits").isHide = true
      Alpine.store("productGranits").matchingcolor = false
    }
    await this._openWith(options)
    await this._loadLink(templatePath)
  }

  // Open the target link into a new modal, resetting options.
  openLink(event) {
    const target = event.currentTarget
    if (target.dataset.modalTitle === "false") {
      document.getElementById("custom-modal-loading").classList.add("custom-modal-loading")
    }
    if (target.classList.value.split(" ").includes("monument-link")) {
      const modalInfo = document.getElementsByClassName("modal-contents")[0]
      if (modalInfo) modalInfo.classList.add("modal-width")
    }

    const link = target.getAttribute("link") || target.href
    const data = target.dataset
    this.open(link, {
      dark: data.modalDark === "true",
      maximized: data.modalMaximized === "true",
      overflow: data.modalOverflow,
      title: data.modalTitle === "true" && target.title,
      className: data.modalClassName,
    })
    event.stopPropagation()
    event.preventDefault()
  }

  openStockForm(event) {
    const target = event.currentTarget
    if (target.classList.value.split(" ").includes("monument-link")) {
      const modalInfo = document.getElementsByClassName("modal-contents")[0]
      if (modalInfo) modalInfo.classList.add("modal-width")
    }

    const data = target.dataset
    this.open(event.currentTarget.href, {
      dark: data.modalDark === "true",
      maximized: data.modalMaximized === "true",
      overflow: data.modalOverflow,
      title: data.modalTitle === "true" && target.title,
      className: data.modalClassName,
    })
    event.stopPropagation()
    event.preventDefault()
  }

  async openLinkFromButton(event) {
    const modalInfo = document.getElementsByClassName("modal-contents")[0]
    if (modalInfo) modalInfo.classList.add("modal-width")
    const link = event.currentTarget.getAttribute("data-link")
    await this.open(link, { maximized: true })
    event.stopPropagation()

    // GTM
    GtmController.displayComparatorModal()
    // end GTM
    if (Alpine.store("catalogs").comparatorColor === null || Alpine.store("catalogs").comparatorColor === 0) {
      // hide layout filter
      Alpine.store("catalogs").comparatorLayoutDisplay = false
    } else {
      // create layout select for modal comparator filter
      createSelect()
      disableLayoutSelect("comparatorlayout")
      // END select layout
    }
  }

  openGranitModal(event) {
    const target = event.currentTarget
    if (target.classList.value.split(" ").includes("monument-link")) {
      const modalInfo = document.getElementsByClassName("modal-contents")[0]
      if (modalInfo) modalInfo.classList.add("modal-width")
    }

    const granit = Alpine.store("granit").granits.find(item => item.reference === target.id)

    Alpine.store("gpg").product = granit
    if (granit.defaultImage !== null) {
      Alpine.store("gpg").getNewconfig(0, granit.defaultImage.image, granit.defaultImage.translation_key)
    } else {
      Alpine.store("gpg").getNewconfig(null)
    }
    this.open("/granite_preview?ref=" + target.id)

    // Preview granit - gtm event load
    GtmController.displayGranit(granit)

    event.stopPropagation()
    event.preventDefault()
  }

  openStockModal(event) {
    const target = event.currentTarget
    if (target.classList.value.split(" ").includes("monument-link")) {
      const modalInfo = document.getElementsByClassName("modal-contents")[0]
      if (modalInfo) modalInfo.classList.add("modal-width")
    }

    const stock = Alpine.store("stock").stocksVisible.find(item => item.reference === target.id)

    Alpine.store("gpg").product = stock
    this.open("/stock_preview?id=" + target.id)

    event.stopPropagation()
    event.preventDefault()
  }

  openFormLink(event) {
    event.preventDefault()
    event.stopPropagation()
    const target = event.currentTarget

    // Check if the form is already appeared
    let orderButton = document.querySelector(".order-button")
    if (orderButton.classList.contains("button-secondary-hover")) {
      this.closeForm()
      return
    }

    if (target.classList.value.split(" ").includes("monument-link")) {
      const modalInfo = document.getElementsByClassName("modal-contents")[0]
      if (modalInfo) modalInfo.classList.add("modal-width")
    }

    let attrData = null
    let configButton = document.querySelector(".configuration-button")
    if (configButton) {
      configButton.classList.remove("button-primary")
      configButton.classList.add("button-inactive")
      attrData = configButton.getAttribute("data-action")
      configButton.setAttribute("data-action", attrData.replace("click->modal#openButtonLink", ""))
      configButton.setAttribute("data-action", "click->modal#disableButtons")
    }

    if (orderButton) {
      orderButton.classList.remove("button-secondary")
      orderButton.classList.add("button-secondary-hover")
      attrData = orderButton.getAttribute("data-action")
      if (attrData) {
        orderButton.setAttribute("data-action", attrData + " click->modal#disableButtons")
      }
      let buttonIcon = orderButton.querySelector("span")
      if (buttonIcon) {
        buttonIcon.classList.remove("text-dark-icon")
        buttonIcon.classList.add("text-light-icon")
      }
    }

    let stockModal = document.querySelector(".stock-preview")
    if (stockModal) {
      let left = stockModal.querySelector(".left")
      if (left) {
        left.classList.add("mobile-hide")
        if (window.innerWidth < 1050) {
          left.style.display = "none"
        }
      }
      let right = stockModal.querySelector(".right")
      if (right) {
        right.style.display = "none"
      }
    }

    fetch(event.currentTarget.dataset.href)
      .then(response => {
        if (response.ok) return response.text()
        throw new Error("Network response was not ok.")
      })
      .then(html => {
        const formContainer = document.getElementById("form-container")
        if (formContainer) {
          formContainer.innerHTML = html
          document.getElementById("stock-order").style.display = "grid"
        }
      })
      .catch(error => {
        console.error("Fetch error: ", error)
      })
  }

  disableButtons(event) {
    event.preventDefault()
    event.stopPropagation()
  }

  closeForm(event = false) {
    document.getElementById("stock-order").style.display = "none"

    let configButton = document.querySelector(".configuration-button")
    let attrData = null
    if (configButton) {
      configButton.classList.remove("button-inactive")
      configButton.classList.add("button-primary")
      attrData = configButton.getAttribute("data-action")
      configButton.setAttribute("data-action", "click->modal#openButtonLink")
    }

    let orderButton = document.querySelector(".order-button")
    if (orderButton) {
      orderButton.classList.remove("button-secondary-hover")
      orderButton.classList.add("button-secondary")
      attrData = orderButton.getAttribute("data-action")
      if (attrData) {
        orderButton.setAttribute("data-action", attrData.replace("click->modal#disableButtons", ""))
      }

      let buttonIcon = orderButton.querySelector("span")
      if (buttonIcon) {
        buttonIcon.classList.remove("text-light-icon")
        buttonIcon.classList.add("text-dark-icon")
      }
    }

    let stockModal = document.querySelector(".stock-preview")
    if (stockModal) {
      let left = stockModal.querySelector(".left")
      left.classList.remove("mobile-hide")
      if (left) {
        left.style.display = "block"
      }
      let right = stockModal.querySelector(".right")
      if (right) {
        right.style.display = "block"
      }
    }
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  openButtonLink(event) {
    event.preventDefault()
    event.stopPropagation()
    const url = event.currentTarget.dataset.href
    window.location.href = url
  }

  async openMonumentModal(event) {
    const target = event.currentTarget
    if (event.target.getAttribute("data-open-preview") === "false" || target.getAttribute("data-open-preview") === "false") {
      return
    }

    Alpine.store("catalogs").fromComparatorModal = target.getAttribute("data-fromcomparatormodal") === "true"
    // GET CURRENT MONUMENT
    let monument
    // if selected monument is from modal comparator
    if (event.currentTarget.getAttribute("data-fromComparator") === "true") {
      // monument selected is from comparator
      monument = Alpine.store("catalogs").monumentsToCompare?.find(i => i.reference === target.id)
    } else if (event.currentTarget.getAttribute("data-fromProductGranits") === "true") {
      // monument selected is from page product granits
      monument = Alpine.store("productGranits").product
    } else {
      monument = Alpine.store("catalogs").productsVisible?.find(i => i.reference === target.id)
      if (monument) monument.name = monument.footstoneName
    }
    // In case we click on secondary preconfig, we should show preview of original monument (not 100% exist in productsVisible)
    if (!monument) {
      monument = Alpine.store("catalogs").oldproductsVisible?.find(item => item.reference === target.id)
    }
    // END GET CURRENT MONUMENT

    const granit = target.getAttribute("granit") ? target.getAttribute("granit") : ""
    let granitSecondary = target.getAttribute("granitSecondary") ? target.getAttribute("granitSecondary") : ""

    // const enableBicolorRedirection = target.getAttribute("data-enable-bicolor-redirection") === "true" // redirection feature
    // let targetMonument = Alpine.store("catalogs").isBicolorProducts.find(item => item.productId === monument.bicoloreMonument)

    //
    if (target.classList.value.split(" ").includes("monument-link")) {
      const modalInfo = document.getElementsByClassName("modal-contents")[0]
      if (modalInfo) modalInfo.classList.add("modal-width")
    }
    if (event.currentTarget.getAttribute("preconfigIndex")) {
      const index = parseInt(event.currentTarget.getAttribute("preconfigIndex"))
      const preconfig = monument.preconfigs[index]
      const image = event.currentTarget.getAttribute("image")
      Alpine.store("gpg").getNewconfig(index + 2, image, null, preconfig)
    }

    if (Alpine.store("gpg").preconfigSec) {
      Alpine.store("gpg").preconfigSec.granit = granit.split("-")[0]
      Alpine.store("gpg").preconfigSec.gm = granit.split("-")[0]
      Alpine.store("gpg").preconfigSec.gs = granitSecondary.split("-")[0]
      // Alpine.store("gpg").newconfig = true
    } else {
      // show default monument
      Alpine.store("gpg").newconfig = false
      Alpine.store("gpg").productconfig = null
      // end show default monument
    }
    await Alpine.store("catalogs").getMonument(monument, granit, granitSecondary)
    const path = target.getAttribute("link")
    this.open(`${path}?catalog_type=${Alpine.store("catalogs").catalog_type}&granit=${granit}&granitSecondary=${granitSecondary}&mntproduct=${monument.productId}`)
    // Preview product monument - gtm event load
    const granitRef = target.getAttribute("data-gtm-product-granit-param")
    GtmController.displayProduct(Alpine.store("gpg").product, granitRef)

    event.stopPropagation()
    event.preventDefault()
  }

  openAccessoireModal(event) {
    const target = event.currentTarget
    const catalogType = target.getAttribute("catalog_type")
    if (target.classList.value.split(" ").includes("monument-link")) {
      const modalInfo = document.getElementsByClassName("modal-contents")[0]
      if (modalInfo) modalInfo.classList.add("modal-width")
    }

    // show default accessory
    Alpine.store("gpg").newconfig = false
    Alpine.store("gpg").productconfig = null
    // end show default accessory

    // if selected accessory from page product granits
    if (event.currentTarget.getAttribute("data-fromProductGranits") === "true") {
      Alpine.store("catalogs").fromProductGranits = true
    }

    const granit = target.getAttribute("granit") ? target.getAttribute("granit") : ""
    Alpine.store("catalogs").getAccessory(target.id, granit)
    const path = target.getAttribute("link")
    this.open(`${path}?accessory=${target.id}&granit=${granit}&catalog_type=${catalogType}`)

    // Preview product accessory - gtm event load
    const isAccessory = target.getAttribute("data-gtm-is-accessory-param") === "true"
    const granitRef = target.getAttribute("data-gtm-product-granit-param")
    const accessory = Alpine.store("catalogs").productsVisible?.find(item => item.reference === target.id)
    GtmController.displayProduct(accessory, granitRef, isAccessory)

    event.stopPropagation()
    event.preventDefault()
  }

  async opengranits(event) {
    let goTo = ""
    const target = event.currentTarget
    const path = target.getAttribute("link")
    const type = target.getAttribute("type")
    const catalogType = target.getAttribute("catalog_type")
    switch (type) {
      case "ACC":
        goTo = Alpine.store("catalogs").accessorygranits(path, catalogType)
        break
      case "MNT":
        Alpine.store("productGranits").initProducts(catalogType)
        goTo = Alpine.store("catalogs").monumentgranits(path, catalogType)
        break
    }
    this.open(goTo)
      .then(() => {
        createSelect()
        // set selected product
        Alpine.store("productGranits").initProducts(type)
      })
    event.stopPropagation()
    event.preventDefault()
  }

  openExtranet2023Modal(event) {
    this.open("/extranet2023")
    event.stopPropagation()
    event.preventDefault()
  }

  // Open the target link into the current modal, without replacing current
  // options, making the iframe act like an iframe.
  followLink(event) {
    this._loadLink(event.currentTarget.href)
    event.stopPropagation()
    event.preventDefault()
  }

  openIframe(event) {
    const target = event.currentTarget
    const data = target.dataset
    let w = parseInt(data.width, 10)
    let h = parseInt(data.height, 10)
    const ratio = w / h

    w = Math.min(w, window.innerWidth * 0.9)
    h = w / ratio

    let iframe = document.createElement("iframe")
    iframe.src = data.href || target.href
    iframe.width = w
    iframe.height = h

    this.openWithDOM(iframe)

    event.stopPropagation()
    event.preventDefault()
  }

  openWithDOM(element, options = {}) {
    this._openWith(options)
    this.setContents("")
    this.bodyTarget.appendChild(element)
  }

  setContents(html) {
    if (this.execScripts) {
      this.bodyTarget.innerHTML = exec.prepare(html)
      exec.replace(this.contentsTarget)
    } else {
      this.bodyTarget.innerHTML = html
    }
  }

  updateContents(event) {
    let xhr

    switch (event.type) {
      case "ajax:error":
      case "ajax:success":
        xhr = event.detail[2]
        this.setContents(xhr.response)
        break
      case "ajax:complete":
        xhr = event.detail[0]
        this.setContents(xhr.response)
        break
      default:
        throw new Error(`ModalController#updateContents: unknown event type ${event.type}`)
    }
  }

  async _loadLink(templatePath) {
    const response = await get(templatePath)
    switch (response.status) {
      case 401:
        // session expired: redirect to login page
        window.location.href = response.headers.get("x-location")
        break
      case 409:
        // conflicting action: close & delegate to controller
        this.close()
        if (this.currentController) {
          this.currentController.onConflict(response)
        }
        break
      default:
        this.setContents(await response.text())
    }
  }

  _openWith(options) {
    this._currentOptions = options || {}
    this.containerTarget.classList.add("is-open")
    this.setOption(options, "dark")
    this.setOption(options, "maximized")
    this.setTitle(options.title)
    this.contentsTarget.style.overflow = options.overflow || ""
    if (options.className) {
      this.containerTarget.classList.add(options.className)
    }
    this.currentController = options.controller
  }

  setOption(options, name) {
    if (options[name]) {
      this.containerTarget.classList.add(`is-${name}`)
    } else {
      this.containerTarget.classList.remove(`is-${name}`)
    }
  }

  setTitle(value) {
    if (value && !value.match(/\\s*$/)) {
      this.titleTarget.innerText = value
      this.containerTarget.classList.add("has-title")
    } else {
      this.titleTarget.innerText = ""
      this.containerTarget.classList.remove("has-title")
    }
  }

  close(event) {
    // GTM
    GtmController.closeModal()
    // end GTM

    this.containerTarget.classList.add("close")
    setTimeout(() => {
      this.containerTarget.classList.remove("is-open")
      this.containerTarget.classList.remove("close")
      this.containerTarget.classList.remove("modal-center")
      const modalContent = document.querySelector(".modal-contents")
      if (modalContent) modalContent.classList.remove("modal-width")
      this.contentsTarget.style.overflow = ""
      if (this._currentOptions.className) {
        this.containerTarget.classList.remove(this._currentOptions.className)
      }
      this.setContents(this.originalHTML)
      this._currentOptions = {}

      // Empty Preconfig
      if (Alpine.store("gpg").preconfigSec) {
        Alpine.store("gpg").preconfigSec = null
      }
      // end
    }, 500)

    if (event) event.preventDefault()
  }

  closeOnEscape(event) {
    if (event.keyCode === 27) {
      this.close()
    }
  }

  forward(event) {
    if (event.type === "ajax:complete") {
      const xhr = event.detail[0]
      switch (xhr.status) {
        case 201: // success & created: replace modal content
          this.setContents(xhr.response)
          return
        case 204: // success & no content: just close the modal
          this.close()
          return
        case 401: // session expired: redirect to login page
          window.location.href = xhr.getResponseHeader("x-location")
          return
      }

      if (xhr.status >= 300) {
        // error: we replace modal content with the updated result
        this.setContents(xhr.response)
        return
      }
    }

    // success: forward control to the controller that created the modal
    if (this.currentController) {
      this.currentController.postEvent(event)
    }
  }

  checkAddress(event) {
    let otherAddress = document.getElementsByClassName("other_address")[0]
    let messageArea = document.getElementsByClassName("message-area")[0]
    if (event.target.value === "1") {
      otherAddress.style.display = "block"
      document.getElementById("other_address").setAttribute("required", "required")
      const otherAddressStyle = window.getComputedStyle(otherAddress)
      const otherAddressHeight = otherAddress.offsetHeight + parseInt(otherAddressStyle.marginTop) + parseInt(otherAddressStyle.marginBottom)
      messageArea.style.height = (messageArea.offsetHeight - otherAddressHeight) + "px"
    } else {
      if (!["none", ""].includes(otherAddress.style.display)) {
        let otherAddressStyle = window.getComputedStyle(otherAddress)
        const otherAddressHeight = otherAddress.offsetHeight + parseInt(otherAddressStyle.marginTop) + parseInt(otherAddressStyle.marginBottom)
        messageArea.style.height = (messageArea.offsetHeight + otherAddressHeight) + "px"
        otherAddress.style.display = "none"
        document.getElementById("other_address").removeAttribute("required")
      }
    }
  }

  preventClose(event) {
    const matchingColor = document.getElementById("matchingColor")
    if (matchingColor) {
      matchingColor.classList.add("hidden2")
    }
    event.stopPropagation()
  }

  // ----------------------------- EXPT ------------------------------
  openModalForPreview(event) {
    const target = event.currentTarget
    const data = target.dataset
    this.admin = window.ADMIN_PATH
    this.openAux({
      dark: data.modalDark === "true",
      maximized: data.modalMaximized === "true",
      overflow: data.modalOverflow,
      title: data.modalTitle === "true" && target.title,
      className: data.modalClassName,
    })

    event.stopPropagation()
    event.preventDefault()
  }

  openAux(options) {
    this._openWith(options)
    this._loadRequest(options)
  }

  async _loadRequest() {
    let data = new FormData()
    // Title
    const titleEle = document.getElementById("title")
    if (titleEle) {
      data.append("title", titleEle.value)
    }
    // Texte
    const textEle = document.getElementById("text")
    if (textEle) {
      data.append("text", textEle.value)
    }
    //
    const response = await post(this.admin + "/actualites/show", data)
    switch (response.status) {
      case 401:
        // session expired: redirect to login page
        window.location.href = response.headers.get("x-location")
        break
      case 409:
        // conflicting action: close & delegate to controller
        this.close()
        if (this.currentController) {
          this.currentController.onConflict(response)
        }
        break
      default:
        this.setContents(await response.text())
    }
  }
}